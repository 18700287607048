import React from 'react';
import { purityQuestions } from './purityquestions'
import { ThemeProvider } from '@mui/material/styles';
import { Container, Typography, FormControl, FormControlLabel } from "@mui/material"
import { wpiTheme } from './themed/wpiTheme';
import { CustomCheckbox } from './themed/CustomCheckbox';
import { CustomButton } from './themed/CustomButton';
import { bonusQuestions } from './bonusquestions';

export default function Questions(props: {setScore: React.Dispatch<React.SetStateAction<number>>, setAppState: React.Dispatch<React.SetStateAction<string>>, questionSelection: boolean[], setQuestionSelection: React.Dispatch<React.SetStateAction<boolean[]>>}) {
    const questionList = []
    const bonusList = []
    let qIter = 0;

    for (const question of purityQuestions) {
      let isChecked;
      isChecked = props.questionSelection[qIter];

      questionList.push((
        <FormControlLabel key={qIter} control={
          <ThemeProvider theme={wpiTheme}>
            <CustomCheckbox className="questionCheck" size="small" defaultChecked={isChecked} />
          </ThemeProvider>
        } label={question} sx={{ mb: "3px", mt: "3px" }}/>
      ))
      qIter += 1
    }

    for (const question of bonusQuestions) {
      let isChecked;
      isChecked = props.questionSelection[qIter];

      bonusList.push((
        <FormControlLabel key={qIter} control={
          <ThemeProvider theme={wpiTheme}>
            <CustomCheckbox className="questionCheck" size="small" defaultChecked={isChecked} />
          </ThemeProvider>
        } label={question} sx={{ mb: "3px", mt: "3px" }}/>
      ))
      qIter += 1
    }

    const onCalculateScore = () => {
        let totalScore = purityQuestions.length;
        // Have to do any here b/c TSX cannot cast to HTMLInputElement/Any easily
        let questionAnswers: Array<boolean>
        questionAnswers = []
        document.querySelectorAll("input[type=checkbox]").forEach((element: any) => {
            if (element.checked) {
                totalScore -= 1
                questionAnswers.push(true)
            } else {
                questionAnswers.push(false)
            }
        })
        props.setScore(totalScore)
        props.setAppState("score")
        props.setQuestionSelection(questionAnswers)
    }

    const onClearCheckboxes = () => {
      // Reload the page and the checkboxes are cleared. Easy.
      window.location.reload()
    }
    
    return (
    <Container maxWidth="md">
        <Typography component="div" sx={{ mb: 2 }}>
          The WPI Purity Test has served as a way for WPI students to bond together from Insight and track the maturation of their experiences while on the hill. The test is designed so you enter WPI with nothing checked off. As the terms go by, your score will go down from new experiences on and off the hill.
        </Typography>
        <Typography component="div" sx={{ mb: 3 }}>
          <b>Caution: This is not a bucket list. Completion of all items on this list will result in the ghost of Robert Foisie probably killing you.</b>
        </Typography>
        <Typography component="div" sx={{ mb: 2 }}>
          Click on every item you have done <b>during your time at WPI</b>. For all sex related questions, <b>the definition of sex is up to you and how you want to take the test.</b>
        </Typography>
        <Typography variant="body2" component="div">
          Questions were last updated on July 11, 2023 (hopefully for the last time). Scores taken before this date aren't valid with this updated test.
        </Typography>
        <FormControl sx={{ mt: 1, mb: 2 }}>
          {questionList}
          <Typography variant="h6" component="div" sx={{ mt: 2 }}>
            Bonus Questions:
          </Typography>
          {bonusList}
        </FormControl>
    <ThemeProvider theme={wpiTheme}>
      <CustomButton variant="contained" onClick={onCalculateScore} sx={{ mb: 3, mr: 1 }}>Calculate my score</CustomButton>
      <CustomButton variant="contained" onClick={onClearCheckboxes} sx={{ mb: 3}}>Clear checkboxes</CustomButton>
    </ThemeProvider>
    </Container>
    )
}