export let purityQuestions = [
"1. Told a tour group not to come to this school?",
"2. Had to explain to someone not at WPI how the term system works?",
"3. Been inside every WPI academic building?",
"4. Replied all to an email sent to a mailing list?",
"5. Seen the Turtle Boy or Dolphin Boy statue?",
"6. Cheated or plagiarized on an assignment or exam?",
"7. Used a wellness day to drown yourself in work?",
"8. Stepped on the seal?",
"9. Made a snow structure on the quad?",
"10. Had to go to Health Services two or more times in the same term?",
"11. NR'd a class?",
"12. Pulled an all-nighter?",
"13. Never went to a lecture for a class but still passed?",
"14. Changed your major at some point?",
"15. Gone to the roof of any building on campus?",
"16. Got locked out of your dorm room after-hours?",
"17. Slept overnight in an academic building?",
"18. Drawn something sexual on the quad or field when it's snowing?",
"19. Engaged in a Nerf war on campus?",
"20. Cup stacked or been cup stacked in DAKA?",
"21. Had stomach problems from DAKA food?",
"22. Stolen tableware from a Chartwells location on campus?",
"23. Stolen food from a Chartwells location on campus?",
"24. Crashed on the floor/couch at a friends place?",
"25. Tech tuesdayed?",
"26. Ran from Campus Police or Worcester Police?",
"27. Vandalized or damaged WPI property?",
"28. Thrown a party?",
"29. Got written up for a conduct violation?",
"30. Gotten a room inspection violation from Res Services?",
"31. Got caught by Campus Police or Worcester Police?",
"32. Cried in an academic building on campus?",
"33. Been told you are an object obstructing the door in the Smart World elevator?",
"34. Been in the steam tunnels?",
"35. Flirted with a WPI professor, faculty, or staff member?",
"36. Rode on top of the Gompei statue?",
"37. Rode to completion on the Gompei statue?",
"38. Rode on a Starship robot?",
"39. Been on a date?",
"40. Gone on a date entirely on campus from start to finish?",
"41. Been in a relationship?",
"42. Been in a situationship?",
"43. Drank alcohol?",
"44. Used marijuana?",
"45. Drank alcohol while in an academic building?",
"46. Been drunk or very high?",
"47. Used tobacco?",
"48. Done shrooms, stimulants, or LSD?",
"49. Done cocaine, heroin, fentanyl or horse tranquilizers?",
"50. Gone to class while under the influence?",
"51. Finished the boynton bucket?",
"52. Thrown up from drinking/smoking too much?",
"53. Been crossed?",
"54. Blacked/greened out?",
"55. Been hungover before?",
"56. Used a fake ID before?",
"57. Supplied a minor with drugs they are unable to legally obtain?",
"58. Been under the influence while on IQP or MQP?",
"59. Tech tuesdayed even though you had something before 9am?",
"60. Been to a house party?",
"61. Been to a frat party?",
"62. Made out with someone at a frat party?",
"63. Been to parties at 4 (or more) different frat houses?",
"64. Gotten a frat toaster or microwave?",
"65. Snuck into a frat party?",
"66. Been blacklisted from a frat?",
"67. Stayed until 2 AM at a frat party?",
"68. Been to a darty (day party)?",
"69. Started drinking before 3 PM?",
"70. Been to parties at other colleges?",
"71. Made out with someone in a public place on campus?",
"72. Masturbated while your roommate was sleeping?",
"73. Got caught by your roommate while masturbating?",
"74. Masturbated in the shower of a WPI residential building?",
"75. Had sex?",
"76. Slept the night with a member of the preferred sex?",
"77. Had sex with another WPI student?",
"78. Had sex with a student attending a college other than WPI?",
"79. Had class with someone you've previously had sex with?",
"80. Had sex with someone you met on a dating app?",
"81. Had to kick out your roommate for a sexual act?",
"82. Walked in on your roommate engaging in a sexual act?",
"83. Had sex in the friend group?",
"84. Had sex with 5 or more people?",
"85. Had sex with someone with a two year class difference?",
"86. Engaged in a sexual act outdoors on WPI's campus?",
"87. Engaged in a sexual act in a non-residential WPI building?",
"88. Walked in on a sexual act outdoors on campus or in a non-residential WPI building?",
"89. Had sex in a residential WPI building?",
"90. Had sex with someone you brought home from a frat party?",
"91. Had sex with your current or past TA/PLA/RA?",
"92. Participated in a threesome-or-more?",
"93. Had sex on a WPI-sponsored off-campus trip?",
"94. Had sex with someone in the same project group as you?",
"95. Gotten an STD/STI?",
"96. Used a sex toy?",
"97. Engaged in BDSM or other kinky sexual activities?",
"98. Had sex in the common room of a dorm?",
"99. Completed the Holy Pentagon (having sex with someone at WPI, Holy Cross, Clark, Assumption & Worcester State)?",
"100. Engaged in bestiality (e.g. fucked a goat)?",
"101. Let the fountain water go up your ass?"
]