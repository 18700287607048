import { Box, Toolbar, Typography, ThemeProvider, Link } from "@mui/material"
import { wpiTheme } from './themed/wpiTheme';
import { CustomAppBar } from './themed/CustomAppBar';

export default function Topbar() {
    return (
    <Box sx={{ flexGrow: 1, mb: 3 }}>
        <ThemeProvider theme={wpiTheme}>
            <CustomAppBar position="static">
                <Toolbar>
                <Typography variant="h6" component="div" sx={{ mr: "auto"}}>
                    <Link href="/" underline="hover" variant="h6" color="inherit">
                        WPI Purity Test
                    </Link>
                </Typography>
                </Toolbar>
            </CustomAppBar>
        </ThemeProvider>
    </Box>
    )
}