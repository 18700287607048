import React from 'react';
import { Container, Typography, ThemeProvider } from "@mui/material"
import { wpiTheme } from './themed/wpiTheme';
import { CustomButton } from './themed/CustomButton';
import { purityQuestions } from './purityquestions';

export default function Score(props: {score: number, setAppState: React.Dispatch<React.SetStateAction<string>>}) {
    const onButtonClick = () => {
        props.setAppState("questions")
    }

    const renderScoreSummary = () => {
        // And you're definitely the type of person to look up spoilers for reality TV shows before the finale.
        if (props.score === -8) {
            return "What the actual fuck."
        } else if (props.score <= -5) {
            return "Robert Foisie just hired a hitman against you."
        } else if (props.score <= 0) {
            return "The ghost of Robert Foisie is coming for you."
        } else if (props.score <= 10) {
            return "Hey, you remember that caution about Robert Foisie...right?"
        } else if (props.score <= 20) {
            return "You're about as pure as Robert Foisie's criminal record. Nicely done!"
        } else if (props.score <= 30) {
            return "Impressive score. You're flying pretty close to the sun and I applaud you for that."
        } else if (props.score <= 40) {
            return "You're definitely the type to say you're rawdogging life but you're on like 6 different medications. Don't turn this into a bucket list though."
        } else if (props.score <= 55) {
            return "You've made some bad life decisions but I'm glad to see you still have that 1 brain cell keeping you in check."
        } else if (props.score <= 68) {
            return "You've definitely made it onto Gompei's naughty list. Now you'll get the side eye every time you visit the quad!"
        } else if (props.score === 69) {
            return "Nice."
        } else if (props.score <= 85) {
            return "You're about as pure as the water that comes out of the Brita filter you've been neglecting to replace."
        } else if (props.score < purityQuestions.length - 1) {
            return "You're that random U2 album Apple decided to put in everyone's music library. Songs of Innocence."
        } else if (props.score === purityQuestions.length - 1) {
            return "So glad you went downtown to check out Turtle Boy, isn't it such a majestic statue? Now, get to work on the rest of the questions."
        } else if (props.score === purityQuestions.length) {
            return `If you're taking this on your first day of WPI, congrats, you're taking the test right. If not, literally walk downtown and get a ${purityQuestions.length - 1}.`
        }
    }
    return (
        <Container maxWidth="md" sx={{ textAlign: "center" }}>
            <Typography variant="h6">
                Your Score
            </Typography>
            <Typography variant="h3">
                {props.score}
            </Typography>
            <Typography sx={{ mt: 1 }}>
                {renderScoreSummary()}
            </Typography>
            <ThemeProvider theme={wpiTheme}>
                <CustomButton variant="contained" onClick={onButtonClick} sx={{ mb: 3, mt: 3 }}>Go back</CustomButton>
            </ThemeProvider>
        </Container>
    )
}