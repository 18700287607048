import React, { useState } from 'react';
import './App.css';
import Topbar from './Topbar';
import Questions from './Questions';
import Score from './Score';

function App() {
  const [appState, setAppState] = useState("questions")
  const [score, setScore] = useState(0)
  const [questionSelection, setQuestionSelection] = useState([false])

  const conditionalRender = () => {
    if (appState === "questions") {
      return (<Questions setAppState={setAppState} setScore={setScore} questionSelection={questionSelection} setQuestionSelection={setQuestionSelection}></Questions>)
    } else {
      return (<Score score={score} setAppState={setAppState} />)
    }
  }

  return (
    <div className="App">
      <Topbar />
      {conditionalRender()}
    </div>
  );
}

export default App;
