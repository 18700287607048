import { Checkbox } from "@mui/material";
import { styled } from "@mui/material/styles";
import { wpiTheme } from "./wpiTheme";

export const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
    color: wpiTheme.color,
    '&.Mui-checked': {
        color: wpiTheme.color
    },
    ':hover': {
        'background-color': wpiTheme.buttonHoverBg
    }
}))