import { createTheme } from "@mui/material/styles"

declare module '@mui/material/styles' {
    interface Theme {
      color: string,
      hoverColor: string,
      buttonHoverBg: string
    }
    // allow configuration using `createTheme`
    interface ThemeOptions {
        color?: string,
        hoverColor?: string,
        buttonHoverBg?: string
    }
}

export const wpiTheme = createTheme({
    color: "#ac2b37",
    hoverColor: "#84232c",
    buttonHoverBg: "rgba(172, 43, 55, 0.05)"
})